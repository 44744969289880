<template>
    <div
        class="pb-4"
        id="danhsach-tuyen"
        :style="`bottom: ${
            getDialogDsChuyenDi
                ? '1124'
                : getDialogChiTietChuyenDi
                ? '1030'
                : '120'
        }px;`"
    >
        <div class="row">
            <div
                v-for="(item, index) in data"
                :key="index"
                class="xs5 shadow white--bg mx-2 wrap"
                style="border-radius: 16px; min-width: 280px"
            >
                <div class="px-3">
                    <div class="title pb-0 ellipsis">
                        {{ item.tenTuyen }}
                    </div>
                    <div class="py-3">
                        <div class="row align-center">
                            <i
                                class="mdi mdi-map-clock-outline primary--text"
                            ></i>
                            <div class="font-20 pl-3">
                                {{ item.thoiGian }}
                            </div>
                        </div>
                        <div class="row align-center">
                            <i
                                class="
                                    mdi mdi-map-marker-distance
                                    primary--text
                                "
                            ></i>
                            <div class="font-20 pl-3">
                                {{ item.khoangCach }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    components: {},
    props: {},
    data() {
        return {
            data: [
                // {
                //     id: 1,
                //     tenTuyen: "Thái Nguyên - Hà Nội",
                //     thoiGian: "1 giờ 56 phút",
                //     khoangCach: "88km",
                // },
                // {
                //     id: 1,
                //     tenTuyen: "Thái Nguyên - Hà Nội",
                //     thoiGian: "1 giờ 56 phút",
                //     khoangCach: "88km",
                // },
                // {
                //     id: 1,
                //     tenTuyen: "Thái Nguyên - Hà Nội",
                //     thoiGian: "1 giờ 56 phút",
                //     khoangCach: "88km",
                // },
                // {
                //     id: 1,
                //     tenTuyen: "Thái Nguyên - Hà Nội",
                //     thoiGian: "1 giờ 56 phút",
                //     khoangCach: "88km",
                // },
                // {
                //     id: 1,
                //     tenTuyen: "Thái Nguyên - Hà Nội",
                //     thoiGian: "1 giờ 56 phút",
                //     khoangCach: "88km",
                // },
            ],
        };
    },
    computed: {
        ...mapGetters("timKiemChuyenDi", [
            "getDialogChiTietChuyenDi",
            "getDialogDsChuyenDi",
        ]),
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang='scss' scoped>
.title {
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid $border;
}
#danhsach-tuyen::-webkit-scrollbar {
    display: none;
}
#danhsach-tuyen {
    width: 100vw;
    overflow: auto;
    position: absolute;
    transition: all 0.4s;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>