<template>
    <DxList
        :data-source="getDataDsChuyenDi"
        key-expr="id"
        :hoverStateEnabled="false"
        :focusStateEnabled="false"
        :activeStateEnabled="false"
        :remote-operations="true"
        page-load-mode="nextButton"
        no-data-text="Không có dữ liệu!"
        class="list-style"
        style="padding-top: 55px"
        height="820"
    >
        <template #item="{ data: item }">
            <ItemDanhSachChuyenDiVue
                :item="item"
                @trangThaiChuyenDi="
                    (tt) => {
                        $emit('trangThaiChuyenDi', tt);
                    }
                "
            />
        </template>
    </DxList>
</template>
<script>
import ItemDanhSachChuyenDiVue from "./ItemDanhSachChuyenDi";
import { DxList } from "devextreme-vue";
import { mapGetters } from "vuex";
export default {
    components: { ItemDanhSachChuyenDiVue, DxList },
    props: {},
    computed: {
        ...mapGetters("timKiemChuyenDi", ["getDataDsChuyenDi"]),
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
::v-deep.list-style .dx-list-item-content {
    padding: unset;
}
::v-deep.list-style .dx-empty-message {
    font-size: 24px;
    text-align: center;
}
::v-deep.list-style .dx-list-item-content {
    display: flex;
}
</style>